/* Top-level shared styles */
/* You can keep styles that are shared by different components here */

html {
  height: 100%;
}

body {
  color: #e7e8eb;
  text-align: center;
  background-color: #030d24;
  margin: 40px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
}

h1,
h2,
h3,
p {
  text-align: center;
}

#root {
  background-color: #030d24;
}

.app {
  padding: 20px 0 40px 0;
  background-color: #030d24;
}

.app__input .MuiInput-underline:after {
  border-color: #e7e8eb;
}

.MuiInputBase-input {
  color: #e7e8eb;
}

.MuiInput-input {
  color: #e7e8eb;
}

.app__input .MuiFormLabel-root,
.app__input .MuiFormLabel-root.Mui-focused {
  color: #e7e8eb;
}

.MuiTextField-root.app__horizontal-center,
.MuiButton-root.app__horizontal-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

::-webkit-scrollbar {
    display: none;
}